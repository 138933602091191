import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Home({ data }) {
	return (
		<div className="application">
			<SEO />
			<Layout>
				<div
					css={css`
						font-size: 1rem;
						font-weight: 400;
						text-align: justify;
					`}
				>
					I'm Joey{" "}
					<span role="img" aria-label="girl-emoji">
						👧🏻
					</span>
					— a software engineer and web designer with a background in corporate
					law.
					<br />
					<br />I create web solutions with a focus on contributing to society
					in meaningful ways:{" "}
					<a
						href="https://github.com/joeyqlim/good.sg"
						css={css`
							color: #159f9f;
							&:hover {
								background-color: #d1efef;
							}
						`}
					>
						cultivating community relations
					</a>
					,{" "}
					<a
						href="https://github.com/joeyqlim/DORA"
						css={css`
							color: #a36fe6;
							&:hover {
								background-color: #f0e5ff;
							}
						`}
					>
						enhancing travel planning productivity{" "}
					</a>{" "}
					and{" "}
					<a
						href="https://github.com/joeyqlim/lunch-buddy"
						css={css`
							color: #dc6039;
							&:hover {
								background-color: #fde1d9;
							}
						`}
					>
						combating lunch-time loneliness
					</a>
					.<br />
					<br />I also love{" "}
					<a
						href="https://github.com/joeyqlim/pet-the-catto"
						css={css`
							color: #4b84e5;
							&:hover {
								background-color: #e3efff;
							}
						`}
					>
						cats
					</a>{" "}
					and{" "}
					<a
						href="https://www.flickr.com/photos/limjoeypics/"
						css={css`
							color: #ff9900;
							&:hover {
								background-color: #ffebcc;
							}
						`}
					>
						photography
					</a>
					. Sometimes, I <Link to="/blog">write</Link> about new things that I
					learn.
					<br />
					<br />
				</div>
			</Layout>
		</div>
	);
}
